import $ from "jquery";
import "./common-string.js";
import "./h1/forms/form.js";
import {parseBoolean} from "./common-type.js";
import {updateAgentPhoneNumber} from "./h1/forms/fields/agent-phone.js";
import Tooltip from "bootstrap/js/dist/tooltip";

/* Add a tooltip to anything with a title */
try {
    let toolTipElements = document.querySelectorAll("[title]");
    toolTipElements.forEach((element) => {
        new Tooltip(element);
    });
} catch (error) {
    /* Do nothing */
}

/* Activate any navigation link associated with the current page URL */
$(".nav-link").removeClass("active").removeAttr("aria-current");
$("a.nav-link[href=\"" + location.pathname + "\"]").addClass("active").attr("aria-current", "page");

if (parseBoolean(sessionStorage.getItem("isMedicare"))) {
    updateAgentPhoneNumber(sessionStorage.getItem("agentPhoneNumberOver65"), sessionStorage.getItem("agentPhoneNumberOver65Uri"));
} else {
    updateAgentPhoneNumber(sessionStorage.getItem("agentPhoneNumberUnder65"), sessionStorage.getItem("agentPhoneNumberUnder65Uri"));
}

$("a").filter(function () {
    return this.hostname && this.hostname !== location.hostname;
})
    .append("&nbsp;<sup class=\"bi bi-box-arrow-up-right\"></sup>")
    .attr("target", "_blank")
    .attr("rel", "noopener");

$("[target=_blank]").attr("aria-label", "opens in new window");

/* Add event for scrolling events. */
$(window).on("scroll", function () {
    if ($(this).scrollTop() < 150) {
        $("body").removeClass("scrolled");
    } else {
        $("body").addClass("scrolled");
    }
});

$(window).on("resize", function () {
    $("#header-shim").css("min-height", $("header").height() + "px");
});
window.dispatchEvent(new Event("resize"));

/* Adds a jQuery selector for data attributes.
 * See https://stackoverflow.com/questions/9499998/jquery-select-all-elements-that-have-jquery-data */
$.extend($.expr[":"], {
    data: $.expr.createPseudo ?
        $.expr.createPseudo(function (dataName) {
            return function (elem) {
                return !!$(elem).data(dataName);
            };
        }) : function (elem, i, match) {
            return !!$(elem).data(match[3]);
        },
});

/**
 * Scroll to the first element with the specified selector.
 *
 * @param {string} selector the selector to scroll to
 */
export function scrollTo(selector) {
    const element = $(selector);
    if (element.length) {
        element.get(0).scrollIntoView({behavior: "smooth"});
    }
}
